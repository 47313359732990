import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './components/Index'
import Vote from './components/Vote';
import Manage from './components/Manage';
import Reveal from './components/Reveal';

const App: React.FC = () => {
  return (
    <Router>
      <div className="container mx-auto">
        <Routes>
          <Route path="*" element={<Index />} />
          <Route path="/:reference" element={<Index />} />
          {/* <Route path="/vote/:reference" element={<Vote />} /> */}
          <Route path="/gr-manager" element={<Manage />} />
          <Route path="/gr-reveal/:filter" element={<Reveal />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;