import React, { useEffect, useState } from 'react';

interface Data {
  ID: number,
  DATE: string,
  REFERENCE: string,
  NOM_PRENOM: string,
  SEXE: number,
  PRENOM_GARCON: string,
  PRENOM_FILLE: string,
  TERME: string,
  POIDS: number,
  TAILLE: number
}

const Manage: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [dataAnalaysed, setDataAnalaysed] = useState({"SEXEM": 0, "SEXEF": 0});
  const [dataDifference, setDataDifference] = useState({termeMin: new Date("2999-01-01"), poidsMin: 999999, tailleMin: 999999, termeMax: new Date("1999-01-01"), poidsMax: 0, tailleMax: 0});
  const [parPoids, setParPoids] = useState(0);
  const [parTaille, setParTaille] = useState(0);
  const [parTerme, setParTerme] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [score, setScore] = useState<{ [key: number]: { vote: any, points: number } }>({});

  const [resultat, setResultat] = useState({
    prenom: "Martin",
    terme: new Date("2025-02-12").setUTCHours(-1),
    poids: 2930,
    taille: 49,
  });

  const updateResultat = (key: keyof typeof resultat, value: any) => {
    setResultat((prevResultat) => ({
      ...prevResultat,
      [key]: key === "terme" ? new Date(value).setUTCHours(-1) : Number(value),
    }));
    setDataDifference({termeMin: new Date("2999-01-01"), poidsMin: 999999, tailleMin: 999999, termeMax: new Date("1999-01-01"), poidsMax: 0, tailleMax: 0});
  };

  const formatDateToLocal = (isoDateString: string, timeZone: string): string => {
    const date = new Date(isoDateString);
  
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timeZone,
      hour12: false
    };
  
    // Format the date using Intl.DateTimeFormat
    const formatter = new Intl.DateTimeFormat('fr-FR', options);
    const parts = formatter.formatToParts(date);
    
    // Extract parts
    const day = parts.find(part => part.type === 'day')?.value || '';
    const month = parts.find(part => part.type === 'month')?.value || '';
    const year = parts.find(part => part.type === 'year')?.value || '';
    const hour = parts.find(part => part.type === 'hour')?.value || '';
    const minute = parts.find(part => part.type === 'minute')?.value || '';
    const second = parts.find(part => part.type === 'second')?.value || '';
  
    return `${day}/${month}/${year} à ${hour}:${minute}:${second}`;
  };

  const getAllData = async () => {
    const response = await fetch('https://gr-api.bregand.fr/getalldata', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
        setError(response.status.toString());
        return;
    }

    const datas = await response.json();

    setData(datas);
    analyseData(datas);
  }

  const analyseData = (data: Data[]) => {
    let sexeM = 0, sexeF = 0;
    data.forEach(item => {
      (item.SEXE ? sexeM++ : sexeF++);
      if(item.POIDS > dataDifference.poidsMax) dataDifference.poidsMax = item.POIDS;
      else if(item.POIDS < dataDifference.poidsMin) dataDifference.poidsMin = item.POIDS;
      if(item.TAILLE > dataDifference.tailleMax) dataDifference.tailleMax = item.TAILLE;
      else if(item.TAILLE < dataDifference.tailleMin) dataDifference.tailleMin = item.TAILLE;
      const termeItem = new Date(item.TERME);
      console.log(termeItem, new Date(resultat.terme));
      if(termeItem > dataDifference.termeMax) dataDifference.termeMax = termeItem;
      else if(termeItem < dataDifference.termeMin) dataDifference.termeMin = termeItem;
    });

    if(resultat.poids > dataDifference.poidsMax) dataDifference.poidsMax = resultat.poids;
    else if(resultat.poids < dataDifference.poidsMin) dataDifference.poidsMin = resultat.poids;
    if(resultat.taille > dataDifference.tailleMax) dataDifference.tailleMax = resultat.taille;
    else if(resultat.taille < dataDifference.tailleMin) dataDifference.tailleMin = resultat.taille;
    const termeResult = new Date(resultat.terme);
    if(termeResult > dataDifference.termeMax) dataDifference.termeMax = termeResult;
    else if(termeResult < dataDifference.termeMin) dataDifference.termeMin = termeResult;

    console.log("poidsMax:", dataDifference.poidsMax, "poidsMin:", dataDifference.poidsMin, "diff:", dataDifference.poidsMax - dataDifference.poidsMin);
    console.log("tailleMax:", dataDifference.tailleMax, "tailleMin:", dataDifference.tailleMin, "diff:", dataDifference.tailleMax - dataDifference.tailleMin);
    console.log("termeMax:", dataDifference.termeMax.getTime(), "termeMin:", dataDifference.termeMin.getTime(), "diff:", dataDifference.termeMax.getTime() - dataDifference.termeMin.getTime());

    const ecartPlusGrandPoids = (dataDifference.poidsMax - resultat.poids) > (resultat.poids - dataDifference.poidsMin) ? (dataDifference.poidsMax - resultat.poids) : (resultat.poids - dataDifference.poidsMin);
    console.log('ecartLePlusGrandPoids:', ecartPlusGrandPoids);
    setParPoids(1/ecartPlusGrandPoids);
    console.log('pointsARetirerParG:', 1/ecartPlusGrandPoids);

    const ecartPlusGrandTaille = (dataDifference.tailleMax - resultat.taille) > (resultat.taille - dataDifference.tailleMin) ? (dataDifference.tailleMax - resultat.taille) : (resultat.taille - dataDifference.tailleMin);
    console.log('ecartLePlusGrandTaille:', ecartPlusGrandTaille);
    setParTaille(1/ecartPlusGrandTaille);
    console.log('pointsARetirerParCm:', 1/ecartPlusGrandTaille);

    let ecartPlusGrandTerme = (dataDifference.termeMax.getTime() - termeResult.getTime()) > (termeResult.getTime() - dataDifference.termeMin.getTime()) ? (dataDifference.termeMax.getTime() - termeResult.getTime()) : (termeResult.getTime() - dataDifference.termeMin.getTime());
    ecartPlusGrandTerme = ecartPlusGrandTerme;
    console.log('ecartLePlusGrandTerme:', ecartPlusGrandTerme);
    setParTerme(1/ecartPlusGrandTerme);
    console.log('pointsARetirerParMs:', 1/ecartPlusGrandTerme);

    setDataAnalaysed({"SEXEM": sexeM, "SEXEF": sexeF});
  }

  const sort = () => {
    const newScore: { [key: number]: { vote: any, points: number } } = {};

    data.forEach(item => {
      const multi = 2;

      let points = 0;
      if(item.SEXE) points += 1;
      if(item.PRENOM_GARCON.toUpperCase() == resultat.prenom.toUpperCase()) points += 1;
      if(item.TERME) points += (1-Math.abs(new Date(item.TERME).getTime()-new Date(resultat.terme).getTime())*parTerme)*multi;
      if(item.POIDS) points += (1-Math.abs(item.POIDS-resultat.poids)*parPoids)*multi;
      if(item.TAILLE) points += (1-Math.abs(item.TAILLE-resultat.taille)*parTaille)*multi;
      newScore[item.ID] = { vote: item, points: points };
    });

    setScore(newScore);
  }

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    sort();
  }, [dataAnalaysed]);

  useEffect(() => {
    console.log(resultat);
    analyseData(data);
  }, [resultat]);

  const garcon = Math.round(dataAnalaysed.SEXEM * 100 / (dataAnalaysed.SEXEM + dataAnalaysed.SEXEF));
  const fille = Math.round(dataAnalaysed.SEXEF * 100 / (dataAnalaysed.SEXEM + dataAnalaysed.SEXEF));

  return (
    <div> {error}
      <h2 className="text-4xl mb-3">GR MANAGER</h2>
      <div>
        <div><input type="date" value={new Date(resultat.terme + 3600000).toISOString().split("T")[0]} onChange={(e) => updateResultat("terme", e.target.value)} className='border text-center' /> Terme</div>
        <div><input type="number" value={resultat.poids} onChange={(e) => updateResultat("poids", e.target.value)} className='border text-center' /> Poids</div>
        <div><input type="number" value={resultat.taille} onChange={(e) => updateResultat("taille", e.target.value)} className='border text-center' /> Taille</div>
      </div>
      <div className='mb-3'>{Object.entries(score)
          .sort((a, b) => b[1].points - a[1].points) // Tri des scores par ordre décroissant
          .map(([id, person]) => (
            <div key={id}>
              [{person.points.toFixed(3)}] {person.vote.NOM_PRENOM}
            </div>
          ))}
      </div>
      <div className="uppercase">Poids de {dataDifference.poidsMin + "g à " + dataDifference.poidsMax}g (différence de {dataDifference.poidsMax - dataDifference.poidsMin}g)</div>
      <div className="uppercase">Taille {dataDifference.tailleMin + "cm à " + dataDifference.tailleMax}cm (différence de {dataDifference.tailleMax - dataDifference.tailleMin}cm)</div>
      <div>{data.map((item, index) => (
        <div key={index} className="my-3 rounded bg-slate-200 px-2 py-3">
          <div className="text-xl font-bold truncate">[{ score[item.ID] && score[item.ID].points.toFixed(3) }/5] ({item.REFERENCE}) {item.NOM_PRENOM}</div>
          <div>[{ item.SEXE ? "1" : "0" }] Sexe: {item.SEXE ? "garçon" : "fille"}</div>
          <div>[{ item.SEXE ? item.PRENOM_GARCON.toUpperCase() == resultat.prenom.toUpperCase() ? "1" : "0" : "0" }] Prénom {item.SEXE ? "garçon: " + item.PRENOM_GARCON : "fille: " + item.PRENOM_FILLE}</div>
          <div>[{ !item.SEXE ? item.PRENOM_GARCON.toUpperCase() == resultat.prenom.toUpperCase() ? "1" : "0" : "0" }] Prénom {!item.SEXE ? "garçon: " + item.PRENOM_GARCON : "fille: " + item.PRENOM_FILLE}</div>
          <div>[{ item.TERME ? (1-Math.abs(new Date(item.TERME).getTime()-new Date(resultat.terme).getTime())*parTerme).toFixed(3) : "0" }]Terme: {formatDateToLocal(item.TERME, "Europe/Paris").split(" ")[0]}</div>
          <div>[{ item.POIDS ? (1-Math.abs(item.POIDS-resultat.poids)*parPoids).toFixed(3) : "0" }] Poids: {item.POIDS} g</div>
          <div>[{ item.TAILLE ? (1-Math.abs(item.TAILLE-resultat.taille)*parTaille).toFixed(3) : "0" }] Taille: {item.TAILLE} cm</div>
          <div className="text-sm truncate">{formatDateToLocal(item.DATE, "Europe/Paris")}</div>
        </div>
      ))}</div>
    </div>
  );
};

export default Manage;
